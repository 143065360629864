import React, {useEffect} from "react";
import {useDebounceFn} from "ahooks";

export function trackInputChange(name, value) {
  _hmt.push(['_trackEvent', 'interaction', 'input', name, value]);
  gtag('event', 'custom_input', {
    event_label: name,
    value
  })
}

export function trackClick(title) {
  _hmt.push(['_trackEvent', 'interaction', 'click', title, 1]);
  gtag('event', 'custom_click', {
    event_label: title,
    value: 1
  })
}

export function trackApiCall(url, params = {}) {
  const [path, query] = url.split('?')
  const usp = new URLSearchParams(query || '')
  Object.entries(params).forEach(([k, v]) => {
    usp.set(k, String(v))
  })
  _hmt.push(['_trackEvent', 'api', 'apiCall', path, usp.toString()])
  gtag('event', 'custom_apiCall', {
    event_label: path,
    value: decodeURIComponent(usp.toString())
  })
}

export function trackPage(path) {
  _hmt.push(['_trackPageview', path]);
  gtag('event', 'page_view')
}

export function withTrackingForm(Form) {
  return function TrackingForm({...props}) {
    const {run: onChange, cancel} = useDebounceFn((e) => {
      const {name, id, value} = e.target
      if ((name || id) && value) {
        trackInputChange(name || id, value)
      }
    }, {trailing: true, leading: false, wait: 200})

    useEffect(() => {
      return cancel
    }, [])

    return React.createElement(Form, {
      ...props,
      onChange,
      onSelect: onChange
    })
  }
}
