import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '@pingcap-inc/tidb-community-ui'

export const RouteLink = styled(Link)`
  color: #aaa;
  //white-space: nowrap;

  &:hover {
    color: ${colors.B4};
  }
`;

export const ActionLink = styled.a`
  color: #aaa;

  &:hover {
    color: ${colors.B4};
  }
`;
