import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '@pingcap-inc/tidb-community-ui';

export const Link = styled.a.attrs({ target: '_blank', rel: 'noreferrer' })`
  &,
  &:hover {
    color: ${colors.B4};
  }
`;

Link.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Link;
