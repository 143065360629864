import React from 'react';
import {Checkbox, Form as OriginalForm, FormItem, Input, Select} from 'formik-antd';
import { Formik } from 'formik';
import {Tooltip} from 'antd';
import { Link, withVerifyCode, RemoteAutoComplete } from '@tidb-community/ui';
import { parse } from 'querystring';
import { useLocation } from 'react-router-dom';
import { utils } from '@tidb-community/common';

import * as styles from './index.module.css'
import {ActionLink, RouteLink} from '/src/components/links';
import { SimpleLayout } from '/src/layout';
import { SubmitButton, PhoneInputPrefix } from '/src/components/form';
import { signup as callSignup, fetchOrganizationOptions } from '/src/api';
import { form, formSchema, initialValues } from './register.form';
import { handleError } from '/src/utils/errors';
import { withTrackingForm } from "/src/utils/track";

const { phone, verifyCode, email, company, manualCompany, agreements } = form;
const { wrapFormikSubmitFunction } = utils.form;

const VerifyInput = withVerifyCode(Input);
const Form = withTrackingForm(OriginalForm)

const { privacy, terms, prefixText: agreementsPrefixText, ...agreementsProps } = agreements;

const defaultOptions = [{ value: '其他' }]

const Page = () => {
  const location = useLocation();
  const query = parse(location.search.slice(1));

  const fetchOpts = {
    AutoComplete: Select,
    fetchOptions: fetchOrganizationOptions,
  };

  const signup = wrapFormikSubmitFunction((data) => {
    const redirectTo = query.redirect_to || '';
    // handle when company is others
    const originalCompany = data[company.name]
    const othersCompany = data[manualCompany.name]

    data = Object.assign({}, data)
    delete data[manualCompany.name]

    if (originalCompany === '其他') {
      data[company.name] = othersCompany
    }
    return callSignup({ ...data, redirect_to: redirectTo }).then(({ redirectTo }) => {
      window.open(redirectTo, '_top');
    });
  }, handleError);

  const sendVerifyCode = wrapFormikSubmitFunction(verifyCode.sendVerifyCode, handleError, true);

  return (
    <Formik validationSchema={formSchema} initialValues={initialValues} onSubmit={signup}>
      {({ values, errors, setErrors, setTouched, setFieldValue, setFieldTouched }) => (
        <Form>
          <FormItem name={company.name}>
            <RemoteAutoComplete {...fetchOpts} {...company} defaultOptions={defaultOptions} size="large" onSelect={value => {
              if (value !== '其他') {
                setFieldValue(manualCompany, value)
                setFieldTouched(manualCompany, true)
              } else {
                setFieldValue(manualCompany, '')
                setFieldTouched(manualCompany, false)
              }
            }} />
          </FormItem>
          {values[company.name] === '其他' ? (
            <FormItem name={manualCompany.name}>
              <Input {...manualCompany} size='large' />
            </FormItem>
          ) : undefined}
          <FormItem name={email.name}>
            <Input {...email} size="large" />
          </FormItem>
          <FormItem name={phone.name}>
            <Input prefix={<PhoneInputPrefix>+86</PhoneInputPrefix>} {...phone} size="large" />
          </FormItem>
          <FormItem name={verifyCode.name}>
            <VerifyInput
              {...verifyCode}
              sendVerifyCode={() => sendVerifyCode(values[phone.name], { setErrors, setTouched })}
              buttonDisabled={errors[phone.name] || !values[phone.name]}
              size="large"
            />
          </FormItem>
          <FormItem name={agreements.name}>
            <Checkbox {...agreementsProps}>
              {agreementsPrefixText}
              <Link href={privacy.url}>
                <span className="no-wrap">{privacy.title}</span>
              </Link>
              <Link href={terms.url}>
                <span className="no-wrap">{terms.title}</span>
              </Link>
            </Checkbox>
          </FormItem>
          <div className={styles.links}>
            <RouteLink to={`/login${location.search}`}>
              <span className="no-wrap">已有账号？去登录</span>
            </RouteLink>
            <Tooltip placement="bottom" title="发送邮件描述您所遇到的问题，工作人员会在 3-5个工作日联系您" arrowPointAtCenter>
              <ActionLink className="no-wrap" href="mailto:community@tidb.io">注册遇到问题？</ActionLink>
            </Tooltip>
          </div>
          <SubmitButton>注册</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

Page.Layout = SimpleLayout;
Page.layoutProps = {
  headTitle: '注册 | PingCAP Account',
};

export default Page;
