import styled from 'styled-components';
import { colors } from '@pingcap-inc/tidb-community-ui'

import logoSvg from './tidb-logo.svg?url';
import { SIZES } from '/src/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $size }) => ($size === SIZES.small ? 'white' : colors.M2)};
  min-height: 100vh;
`;

export const Main = styled.main`
  box-sizing: content-box;
  padding: 24px;
  border: 1px solid #ededed;
  border-radius: 4px;
  background-color: ${colors.M1};
  width: 360px;
  ${({ $size }) =>
    $size === SIZES.small &&
    `
    border: none;
    padding: 12px 12px 64px;
  `}
`;

export const Logo = styled.img.attrs({ alt: 'logo', src: logoSvg })`
  display: block;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-size: 20px;
  line-height: ${28 / 20};
  color: ${colors.F1};
  font-weight: 600;
  text-align: center;
  margin: 24px 0 0;
`;

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: ${24 / 16};
  color: ${colors.F2};
  font-weight: 400;
  text-align: center;
  margin: 0 0 32px;
`;
