import React from 'react';
import ReactDOM from 'react-dom';
import { HeadProvider } from 'react-head';
import '@pingcap-inc/tidb-community-ui/dist/antd.css'

import './sentry';
import './app.css';
import PageRouter from './router';
import { trackClick } from "./utils/track";


ReactDOM.render(
  <React.StrictMode>
    <HeadProvider>
      <PageRouter />
    </HeadProvider>
  </React.StrictMode>,
  document.getElementById('app')
);

document.body.addEventListener('click', event => {
  if (/^(a|button)$/i.test(event.target.tagName)) {
    trackClick(event.target.textContent)
  }
})
