import styled from 'styled-components';
import { Button as AntButton } from 'antd';

import { colors } from '@pingcap-inc/tidb-community-ui';

export const SendEmailButton = styled(AntButton)`
  height: 22px !important;
  line-height: 22px !important;
  padding: 0 4px !important;

  span {
    font-size: 14px;
    line-height: ${22 / 14};
    color: ${(props) => props.disabled ? colors.F4 : colors.F2};
    font-weight: 400;
    transition: color .2s ease;
      
    &:hover {
      color: ${colors.B4};
    }
  }

  &::before {
    background-color: transparent;
  }
`;
